'use client';

import { PaletteOptions, ThemeOptions, createTheme } from '@mui/material';
import { AIHubPallete, AIHubTypography } from 'mui';

const aiHubPalette: AIHubPallete = {
  grey: {
    '000': '#000000',
    '800': '#292929',
    '700': '#3F3F3F',
    '600': '#5C5C5C',
    '500': '#7A7A7A',
    '400': '#CCCCCC',
    '300': '#EAEAEA',
    '200': '#F7F7F7',
    '100': '#FAFAFA',
    FFF: '#FFFFFF',
  },
  blue: {
    800: '#001A48',
    700: '#022471',
    600: '#003CA5',
    500: '#005AF9',
    400: '#4D8EFF',
    300: '#D2E1FF',
    200: '#E7EFFF',
    100: '#F7FAFF',
  },
  purple: {
    700: '#420B60',
    600: '#003CA5',
    500: '#9F28B5',
    400: '#BE81CC',
    300: '#F4D9F9',
    200: '#FCEBFF',
    100: '#FEF9FF',
  },
  red: {
    700: '#851107',
    600: '#AF1C0F',
    500: '#E33D2E',
    400: '#FF5C4E',
    300: '#FFCEBA',
    200: '#FFE4D9',
    100: '#FDF5F4',
  },
  orange: {
    500: '#FF691D',
  },
  yellow: {
    800: '#533800',
    700: '#895E00',
    600: '#C28400',
    500: '#FEBA01',
    300: '#FFEAAB',
    200: '#FFF4D4',
    100: '#FFFAED',
  },
  green: {
    800: '#003519',
    700: '#005A2A',
    600: '#00823C',
    500: '#3CAD2B',
    300: '#CEEFC9',
    200: '#E1F6E3',
    100: '#F5FBF4',
  },
  blueAlt: {
    600: '#007BC2',
    500: '#009ADF',
    400: '#57CBFF',
    300: '#BBEAFF',
    100: '#F2F9FB',
  },
};

function createCommonThemeOptions(): Omit<ThemeOptions, 'palette'> & {
  palette: Partial<Omit<ThemeOptions['palette'], 'aiHub'>> & {
    aiHub: PaletteOptions['aiHub'];
  };
} {
  const aiHubTypography = createAIHubTypography();
  return {
    palette: {
      aiHub: aiHubPalette,
    },
    typography: {
      aiHub: aiHubTypography,
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            'ai-hub-title-display': 'h1',
            'ai-hub-h1-display': 'h1',
            'ai-hub-h1': 'h1',
            'ai-hub-body': 'p',
            'ai-hub-body-bold': 'p',
            'ai-hub-body-small': 'p',
            'ai-hub-body-small-bold': 'p',
          },
        },
        variants: [
          {
            props: { variant: 'ai-hub-title-display' },
            style: aiHubTypography.titleDisplay,
          },
          {
            props: { variant: 'ai-hub-h1-display' },
            style: aiHubTypography.h1Display,
          },
          {
            props: { variant: 'ai-hub-h1' },
            style: aiHubTypography.h1,
          },
          {
            props: { variant: 'ai-hub-body' },
            style: aiHubTypography.body,
          },
          {
            props: { variant: 'ai-hub-body-bold' },
            style: aiHubTypography.bodyBold,
          },
          {
            props: { variant: 'ai-hub-body-small' },
            style: aiHubTypography.bodySmall,
          },
          {
            props: { variant: 'ai-hub-body-small-bold' },
            style: aiHubTypography.bodySmallBold,
          },
        ],
      },
    },
  };
}

function createLightThemeOptions(): ThemeOptions {
  const commonTheme = createCommonThemeOptions();
  return {
    ...commonTheme,
    palette: {
      ...(commonTheme.palette ?? {}),
      mode: 'light',
      primary: {
        main: '#D2E1FF',
        light: '#F7FAFF',
        dark: '#00349B',
        background: '#F7FAFF',
        border: '#E7EFFF',
        contrastText: '#00349B',
      },
      secondary: {
        main: '#00349B',
      },
      error: {
        main: commonTheme.palette.aiHub.red[600],
        light: '#FDF5F4',
      },
      border: {
        main: '#E7EFFF',
      },
      selected: {
        main: '#F5F5F5',
      },
      cta: {
        main: '#003CA5',
      },
      scratchPad: {
        main: '#FFF6DB',
        light: 'rgba(254, 186, 1, 0.10)',
        background: 'rgba(254, 186, 1, 0.10)',
        border: '#E7EFFF',
        dark: '#FEBA01',
      },
      task: {
        main: '#005A2A',
        light: '#F5FBF4',
        dark: '#005A2A',
        background: '#E1F6E3',
        border: '#E1F6E3',
      },
      advancedSettings: {
        border: '#D2E1FF',
        subtitle: '#5C5C5C',
        sectionHeader: {
          background: '#E7EFFF',
          arrowIcon: '#003CA5',
        },
        actions: {
          text: '#005A2A',
          icon: '#005A2A',
          background: 'white',
        },
      },
    },
    components: {
      ...commonTheme.components,
      MuiLink: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: '#003CA5',
            '&:hover': {
              cursor: 'pointer',
            },
          }),
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&.Mui-selected': {
              backgroundColor: theme.palette.primary.main,
            },
            '&.Mui-selected:hover': {
              backgroundColor: theme.palette.primary.main,
            },
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
            },
          }),
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            '&.MuiButton-containedPrimary:hover': {
              backgroundColor: theme.palette.primary.light,
            },
            '&.MuiButton-containedSecondary:hover': {
              backgroundColor: theme.palette.secondary.light,
            },
            '&.MuiButton-containedError': {
              backgroundColor: theme.palette.error.light,
              color: theme.palette.error.main,
            },
            '&.MuiButton-containedError:hover': {
              backgroundColor: theme.palette.error.main,
              color: theme.palette.error.light,
            },
          }),
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&.Mui-focused': {
              color: theme.palette.secondary.main,
            },
          }),
        },
      },
      MuiChip: {
        variants: [
          {
            style: ({ theme }) => ({
              backgroundColor: theme.palette.task.light,
              color: theme.palette.task.dark,
              fontWeight: 700,
            }),
            props: { variant: 'task' },
          },
        ],
      },
    },
  };
}

function createDarkThemeOptions(): ThemeOptions {
  const commonTheme = createCommonThemeOptions();
  return {
    palette: {
      ...commonTheme.palette,
      mode: 'dark',
      primary: {
        main: '#000000',
        light: '#292929',
        dark: '#022471',
        background: '#292929',
        contrastText: '#3CAD2B',
      },
      secondary: {
        main: '#4D8EFF',
      },
      error: {
        main: '#FFCEBA',
        light: '#3F3F3F',
      },
      border: {
        main: '#34383C',
      },
      selected: {
        main: '#53565A',
      },
      cta: {
        main: '#003CA5',
      },
      scratchPad: {
        main: '#895E00',
        light: '#533800',
        dark: '#FEBA01',
        border: '#34383C',
      },
      task: {
        main: '#3CAD2B',
        light: '#CEEFC9',
        dark: '#003519',
        background: '#000000',
        contrastText: '#3CAD2B',
        border: '#34383C',
      },
      advancedSettings: {
        border: '#5C5C5C',
        subtitle: '#CCCCCC',
        sectionHeader: {
          background: 'aiHub.grey.000',
          arrowIcon: '#4D8EFF',
        },
        actions: {
          text: 'white',
          icon: '#3CAD2B',
          background: 'black',
        },
      },
    },
    components: {
      ...commonTheme.components,
      MuiLink: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: '#4D8EFF',
            '&:hover': {
              cursor: 'pointer',
            },
          }),
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&.Mui-selected': {
              backgroundColor: theme.palette.selected.main,
            },
          }),
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: ({ theme }) => ({
            backgroundColor: theme.palette.primary.light,
          }),
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&.Mui-focused': {
              color: 'white',
            },
          }),
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            '&.MuiButton-containedPrimary:hover': {
              backgroundColor: theme.palette.selected.main,
            },
            '&.MuiButton-containedSecondary:hover': {
              backgroundColor: theme.palette.selected.main,
            },
            '&.MuiButton-containedSelected:hover': {
              backgroundColor: theme.palette.border.main,
            },
          }),
        },
      },
    },
  };
}

function createAIHubTypography(): AIHubTypography {
  return {
    titleDisplay: {
      fontFamily: 'Copernicus,serif',
      fontSize: '40px',
      fontWeight: 700,
      lineHeight: '60px',
    },
    h1Display: {
      fontFamily: 'Copernicus,serif',
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '36px',
    },
    h1: {
      fontFamily: 'Polaris,sans-serif',
      fontSize: '24px',
      fontWeight: 720,
      lineHeight: '36px',
    },
    body: {
      fontFamily: '"Noto Sans",sans-serif',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '22.4px',
    },
    bodyBold: {
      fontFamily: '"Noto Sans",sans-serif',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '22.4px',
    },
    bodySmall: {
      fontFamily: '"Noto Sans",sans-serif',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16.8px',
    },
    bodySmallBold: {
      fontFamily: '"Noto Sans",sans-serif',
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '16.8px',
    },
  };
}

export const themeService = {
  aiHubPalette,
  createTheme(darkMode: boolean) {
    return createTheme(this.getThemeOptions(darkMode));
  },
  getThemeOptions(darkMode: boolean) {
    if (darkMode) {
      return createDarkThemeOptions();
    }
    return createLightThemeOptions();
  },
};
